const Theme = {
  colors: {
    primary: "#000"
  },
  fonts: {
    primary: "Comic Sans MS, cursive, sans-serif"
  }
};

export default Theme;
